import React, { useEffect, useState } from 'react'
import Mispichoshelper from '../utils/Mispichoshelper';
import { CriterionTag } from "../functions/criterionTag";
import number_format from '../functions/numberFormat';

import './AssignmentProcess.css'
import { Tooltip } from 'antd';
import { FaInfoCircle } from 'react-icons/fa';

function AssignmentProcess({ movementId }) {
    const [process, setProcess] = useState({});


    //alert('Hola, soy AssignmentProcess')

    useEffect(() => {
        Mispichoshelper.getAssignmentProcess({ cmm_id: movementId }).then((response) => {
            setProcess(response.payload.data)
        })
    }, [movementId])

    return (
        <div className='GlobalStructure'>
            <div className="GridDosTerceos">
                {process.modules && (Array.isArray(process.modules) ? <ModulesAnalyzedByArray modules={process.modules} /> : <ModulesAnalyzed modules={process.modules} />)}
                <div className='AllRegisters'>
                    {process.process_log &&
                        process.process_log.map((pro, ind) => {
                            let is_array = pro.data ? Array.isArray(pro.data) : null;
                            return <article className='Card'>
                                <div className="CardHead RowCenter SpaceBetween">
                                    <h6 className="notification-rigth">{pro.process} </h6>
                                    <em>{pro.time + ' Hs'}</em>
                                </div>

                                {pro.criterion &&
                                    <div className="rowFlex">
                                        Criterios: {<MetricListItem key={0} data={pro.criterion} />}
                                    </div>
                                }

                                {pro.data &&

                                    ((typeof pro.data['puv_id'] !== 'undefined' || Array.isArray(pro.data)) ?
                                        (
                                            < div >
                                                <div>Petshops: </div>
                                                <ul className={'PetshopList'}>
                                                    {Array.isArray(pro.data) ?
                                                        pro.data.map((data, ind) => <PetshopListItem key={ind} data={data} />)
                                                        :
                                                        <PetshopListItem key={0} data={pro.data} />
                                                    }
                                                </ul>
                                            </div>
                                        )
                                        :
                                        (
                                            < div >
                                                <div>Informacion: </div>
                                                <ul className={'PetshopList'}>
                                                    {Object.entries(pro.data).map(([key, value]) => (
                                                        <li className='PetshopListItem'><strong>{key}</strong>{": " + (typeof (value) === 'number' ? '$ ' + number_format(value, 2, ',', '.') : value)}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )
                                    )
                                }
                            </article>
                        })
                    }
                </div>
            </div>
        </div >
    )
}
export default AssignmentProcess;

const MetricListItem = ({ data }) => {
    return (
        Object.keys(data).map((keyName, i) => {
            return <CriterionTag data={data} keyName={keyName} />
        })
    )
}

const PetshopListItem = ({ data }) => {

    if (data.puv_nombre) {
        return (
            <li className='PetshopListItem'>
                <div >Petshop: {data.puv_nombre}</div>
                <div >Categoría: {data.puv_categoria}</div>
                <div >Distancia del pedido: {data.distancia ? (data.distancia / 1000) + "km " : null}</div>
                {data.score &&
                  <ScoreComponent score={data.score} metrics={data.metrics} />
                }
                <div className="rowFlex" style={{ margin: "5px 0px" }}>
                    {Object.keys(data).map((keyName, i) => {
                        if (typeof data[keyName] == "boolean") {
                            return <CriterionTag data={data} keyName={keyName} />
                        }
                    })}
                </div>
            </li>
        )
    }
    return null;
}

const ScoreComponent = ({ score, metrics }) => {
  const tooltipContent = (
    <div>
      <p>Aceptación: {metrics.acceptance}</p>
      <p>Ticket Promedio: {metrics.average_order}</p>
      <p>Distancia: {metrics.distancia}</p>
      <p>Nivel de Servicio: {metrics.service_level}</p>
    </div>
  );

  return (
    <div className="d-flex align-items-center">
      <div>Score: {score}Pts</div>
      <Tooltip title={tooltipContent} placement="top">
        <FaInfoCircle className="ml-2" />
      </Tooltip>
    </div>
  );
};
const ModulesAnalyzedByArray = ({ modules }) => {

    return (
        <section>
            <div className="Embudo" style={{ margin: "-24px 0 0 -32px" }}>
                {modules && modules.map((module, i) => (
                    <div key={i} className='Card ColFlex AlignCenter RemueveSombra' style={{ backgroundColor: module.status ? 'green' : 'red', color: "#fff" }}>
                        <h5 className='textosBancos'>{module.name}</h5>
                    </div>
                ))}
            </div>
        </section >
    )
}

// DEPRECATED: estructura de modules de proceso de asignacion vieja. ahora se usa tipo array. se deja para imprimir las compras anteriores.
const ModulesAnalyzed = ({ modules }) => {

    const colorToShow = (data, initial_color = "#cfcfcf") => {
        let color = initial_color;
        if (typeof (data) === "boolean") {
            if (data) {
                color = 'green'
            } else {
                color = 'red'
            }
        }
        return color
    }
    return (
        <section>
            <div className="Embudo">
                <div className='Card ColFlex AlignCenter RemueveSombra' style={{ backgroundColor: colorToShow(modules.ZS, 'red'), color: "#fff" }}>
                    <h5 className='textosBancos'>Zona Especial</h5>
                </div>
                <div className='Card ColFlex AlignCenter RemueveSombra' style={{ backgroundColor: colorToShow(modules.CA), color: "#fff" }}>
                    <h5 className='textosBancos'>Cartera</h5>
                </div>
                <div className='Card ColFlex AlignCenter RemueveSombra' style={{ backgroundColor: colorToShow(modules.WH), color: "#fff" }}>
                    <h5 className='textosBancos'>Warehouse</h5>
                </div>
                <div className='Card ColFlex AlignCenter RemueveSombra' style={{ backgroundColor: colorToShow(modules.MP), color: "#fff" }}>
                    <h5 className='textosBancos'>MasterPetshop</h5>
                </div>
                <div className='Card ColFlex AlignCenter RemueveSombra' style={{ backgroundColor: colorToShow(modules.PA), color: "#fff" }}>
                    <h5 className='textosBancos'>Petshop Autoaceptacion</h5>
                </div>
                <div className='Card ColFlex AlignCenter RemueveSombra' style={{ backgroundColor: colorToShow(modules.OP), color: "#fff" }}>
                    <h5 className='textosBancos'>Oportunidad</h5>
                </div>
            </div>
        </section>
    )
}
